<template>
  <div>
    <div class="flex-def flex-warp">
      <div class="flex-def addons-item">
          <img class="flex-def" style="width: 5rem;height: 5rem" :src="elIcon('chain')">
          <div style="margin-left: .5rem" class="flex-def flex-zTopBottom">
            <div style="font-size: 1.2rem">2+1链动模式</div>
            <div class="y-desc">模式插件规则配置</div>
            <div class="flex-def flex-zBetween flex-cCenter" style="margin-top: .5rem;width: calc(20rem - 6rem);">
              <div><el-button type="text" v-if="addons.chain" @click="$router.push({name:'addonsChain'})">进入管理</el-button></div>
              <el-switch :disabled="addons.chain" @change="editInfo('chain')" v-model="addons.chain"></el-switch>
            </div>
          </div>
      </div>
      <div class="flex-def addons-item">
        <img class="flex-def" style="width: 5rem;height: 5rem" :src="elIcon('area_agent')">
        <div style="margin-left: .5rem" class="flex-def flex-zTopBottom">
          <div style="font-size: 1.2rem">区域代理模式</div>
          <div class="y-desc">模式插件规则配置</div>
          <div class="flex-def flex-zBetween flex-cCenter" style="margin-top: .5rem;width: calc(20rem - 6rem);">
            <div><el-button type="text" v-if="addons.area_agent" @click="$router.push({name:'addonsAreaAgent'})">进入管理</el-button></div>
            <el-switch :disabled="addons.area_agent" @change="editInfo('area-agent')" v-model="addons.area_agent"></el-switch>
          </div>
        </div>
      </div>
      <div class="flex-def addons-item">
        <img class="flex-def" style="width: 5rem;height: 5rem" :src="elIcon('knowledge')">
        <div style="margin-left: .5rem" class="flex-def flex-zTopBottom">
          <div style="font-size: 1.2rem">知识付费</div>
          <div class="y-desc">基础插件</div>
          <div class="flex-def flex-zBetween flex-cCenter" style="margin-top: .5rem;width: calc(20rem - 6rem);">
            <div><el-button type="text" v-if="addons.knowledge" @click="$router.push({name:'addonsKnowledge'})">进入管理</el-button></div>
            <el-switch @change="editInfo('knowledge')" v-model="addons.knowledge"></el-switch>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "info",
  data() {
    return {
      addons:{
        chain:false,
        area_agent:false,
        knowledge:false
      },
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$api.addons.basic.info().then(res=>{
        this.addons = res;
      })
    },
    editInfo(name){
      this.$api.addons.basic.infoEdit({open:name}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      }).catch(()=>{
        this.load();
      })
    },
    elIcon(name) {
      return `./${process.env.BASE_URL}image/addons/${name}.png`
    },
  }
}
</script>

<style scoped>
  .addons-item{
    background-color: #ededed;
    border-radius: .2rem;
    padding: .5rem;
    width: 20rem;
    height: 5rem;
    position: relative;
  }
  .addons-item+.addons-item{
    margin-left: 2rem;
  }
</style>